.zoom-image {
    transition: transform 0.3s ease;
  }
  
  .zoom-image:hover {
    transform: scale(1.1);
  }
  
  .slick-prev:before,
.slick-next:before {
  color: orange;
}
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, and Opera */
}
